import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAlert } from '@ezrirx/sourdough'
import Cookies from 'universal-cookie'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { login, resetError } from '../../actions/user'

import LoadingState from '../Common/LoadingState'

import SEO from '../SEO'
import ContentBlock from '../Common/ContentBlock/ContentBlock'
import { getQueryParam } from '../../helpers/urlHelper'

const FROM_PARAM = 'from'

export const Login = (props) => {
  const { t } = useTranslation()
  const cookies = new Cookies()

  const alert = useAlert()

  const [deaNumber, setDeaNumber] = useState('')
  const [password, setPassword] = useState('')
  const [domLoading, setDomLoading] = useState(true)

  const { user, loading, error, isAuthorized, history, location } = props

  useEffect(() => {
    if (Object.keys(user).length !== 0 && isAuthorized) {
      const fromParam = getQueryParam(FROM_PARAM, location)
      const prevPath = fromParam && decodeURIComponent(fromParam)
      history.push(encodeURI(prevPath) || '/')
    }
  }, [user])

  useEffect(() => {
    setDomLoading(false)
  }, [])

  const handleSubmitForm = (e) => {
    e.preventDefault()

    props.login(deaNumber, password, props.history, () => {
      setDeaNumber('')
      setPassword('')
    })
    cookies.set('sub_user_login', false)
  }

  const hasError = error && error.length
  if (hasError) {
    alert.failure(error)
    props.resetError()
  }

  return (
    <>
      <SEO
        title={t('page_titles.sign_in')}
        pathName={history.location.pathname}
      />
      <div className="layout-default login">
        <div className="contents">
          <h1 className="logo">
            <Link to="/" />
          </h1>
          <ContentBlock>
            <ContentBlock.Heading>{t('auth.sign_in')}</ContentBlock.Heading>
            {domLoading ? (
              <LoadingState />
            ) : (
              <ContentBlock.Body>
                <form onSubmit={(e) => handleSubmitForm(e)}>
                  <div className="content-block__container__main">
                    <div className="row margin-bottom-20">
                      <div className="form-group col-12">
                        <label htmlFor="deaNumber">
                          {t('user.dea_number')}
                        </label>
                        <input
                          type="text"
                          id="deaNumber"
                          className="lg full-width"
                          onChange={(e) => setDeaNumber(e.target.value)}
                          value={deaNumber}
                          autoCorrect="off"
                          autoComplete="off"
                          autoCapitalize="none"
                        />
                      </div>
                    </div>
                    <div className="row margin-bottom-10">
                      <div className="form-group col-12">
                        <label htmlFor="password">{t('user.password')}</label>
                        <input
                          type="password"
                          id="password"
                          className="lg full-width"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="row mx-0 mb-3">
                      <Link
                        to="/password/recover"
                        className="text-md gray-dark-link ml-auto"
                      >
                        {t('auth.forgot_password')}
                      </Link>
                    </div>
                    <div className="row -ma">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="lg block button"
                          disabled={
                            loading ||
                            deaNumber.length === 0 ||
                            password.length === 0
                          }
                        >
                          {loading
                            ? t('loading.just_a_sec')
                            : t('auth.sign_in')}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </ContentBlock.Body>
            )}
          </ContentBlock>

          <p className="footer-text">
            {t('auth.dont_have_an_account')}{' '}
            <a href="https://app.ezrirx.com/signup/">{t('auth.sign_up_now')}</a>
          </p>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ user }) => ({
  loading: user.loading,
  error: user.error,
  user: user.userData,
  isAuthorized: user.isAuthorized
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ login, resetError }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login)
