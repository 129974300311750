import React from 'react'
import PropTypes from 'prop-types'
import styles from './ContentBlock.module.css'

const propTypes = {
  /**
   * Sets style of the content block
   *
   * @default 'default'
   * @type {('default'|'transparent')}
   */
  type: PropTypes.string,

  /**
   * Pass-through for inline CSS styles
   *
   * @default null
   */
  style: PropTypes.object
}

const defaultProps = {
  type: 'default',
  style: null
}

const ContentBlockHeading = ({ children }) => (
  <div className={styles.contentBlockHeading}>
    <h2>{children}</h2>
  </div>
)

const ContentBlockBody = ({ children }) => (
  <div className={styles.contentBlockBody}>{children}</div>
)

const ContentBlock = ({ type, style, children }) => (
  <div
    className={`${styles.contentBlock} ${styles[`contentBlockStyle_${type}`]}`}
    style={style}
  >
    {children}
  </div>
)

ContentBlock.Heading = ContentBlockHeading
ContentBlock.Body = ContentBlockBody

ContentBlock.propTypes = propTypes
ContentBlock.defaultProps = defaultProps

export default ContentBlock
