import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

const SEO = props => {
  const siteName = 'EzriRx'
  const [title, setTitle] = useState(siteName)

  useEffect(() => {
    if (props.title) {
      setTitle(props.title)
    }
  }, [props])

  const fullTitle = title === siteName ? title : `${title} - ${siteName}`
  const pathName = props.pathName && props.pathName.split('/')
  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="title" content={fullTitle} />
      {props.pathName && 
      <link rel="canonical" href={`https://m.ezrirx.com/${pathName[1]}/`} />
      }
    </Helmet>
  )
}
export default SEO
